import React, { Fragment } from "react"
import ContentHorizontalCard from "./contentHorizontalCard"

const ContentList = ({ layoutType, content, slugModifier }) => {
  return (
    <div className={`${layoutType !== "list" ? "hidden" : ""}`}>
      {content.map(({ node: content }) => {
        return (
          <Fragment key={content.id}>
            <ContentHorizontalCard
              content={content}
              slugModifier={slugModifier}
            />
          </Fragment>
        )
      })}
    </div>
  )
}

export default ContentList
