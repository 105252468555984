import React, { Fragment } from "react"
import Masonry from "react-masonry-css"
import ContentStackedCard from "./contentStackedCard"

const ContentMasonry = ({ layoutType, content, slugModifier }) => {
  return (
    <Masonry
      className={`flex -mx-2 ${layoutType !== "masonry" ? "hidden" : ""}`}
      columnClassName="px-2"
      breakpointCols={{
        default: 3,
        640: 1,
        768: 1,
        1024: 2,
        1280: 2,
      }}
    >
      {content.map(({ node: content }) => {
        return (
          <Fragment key={content.id}>
            <ContentStackedCard content={content} slugModifier={slugModifier} />
          </Fragment>
        )
      })}
    </Masonry>
  )
}

export default ContentMasonry
