import React, { Fragment } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons"

const ContentStackedCard = ({
  content: {
    title,
    type,
    slug,
    shortDescription,
    image,
    categories,
    published,
  },
  slugModifier,
}) => {
  return (
    <Link
      to={`/${
        slugModifier !== null && slugModifier !== undefined
          ? slugModifier + "/"
          : ""
      }${slug}/`}
    >
      <div className="rounded overflow-hidden shadow-lg hover:shadow-2xl mb-10 md:mb-4">
        <div className="w-full relative">
          {type !== null && type !== undefined && (
            <h3 className="absolute z-10 right-0 bg-brand rounded-sm shadow-md px-2 py-1 uppercase text-white text-sm mt-2 mr-2">
              {type}
            </h3>
          )}
          {categories !== null &&
            categories !== undefined &&
            categories.map(({ id, title }, index) => {
              return (
                <Fragment key={id}>
                  <h3
                    className={`absolute z-10 right-0 bg-brand rounded-small px-2 py-1 uppercase text-white text-sm mt-${
                      index === 0 ? 2 : index * 10
                    } mr-2`}
                  >
                    {title}
                  </h3>
                </Fragment>
              )
            })}
          <Img
            className="z-0 relative mx-auto"
            fluid={image.fluid}
            alt={image.description}
          />
        </div>
        <div className="flex flex-col px-6 py-4">
          <span className="font-bold text-2xl mb-4">{title}</span>
          {(type === "Post" || type === "Review") &&
            published !== null &&
            published !== undefined && (
              <span className="text-lg">
                <FontAwesomeIcon
                  className="text-brand mr-2"
                  size="1x"
                  style={{
                    height: "1rem",
                    width: "1rem",
                  }}
                  icon={faCalendarAlt}
                />
                {published}
              </span>
            )}
          <p className="prose prose-lg max-w-none">{shortDescription}</p>
          <p className="uppercase text-brand text-sm">
            Read More <span className="text-lg">»</span>
          </p>
        </div>
      </div>
    </Link>
  )
}

export default ContentStackedCard
