import React, { useContext } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentMasonry from "../components/content/contentMasonry"
import ContentList from "../components/content/contentList"
import LayoutContext from "../state/context/layout-context"
import ContentListToggleIcons from "../components/content/contentListToggleIcons"

export default ({
  data: {
    contentfulLayoutTemplate: {
      title,
      seoTitle,
      shortDescription,
      slug,
      slugModifier,
      canonical,
    },
    allContentfulCategory: { edges: categories },
  },
  location,
}) => {
  const { layoutType, toggleLayout } = useContext(LayoutContext)

  return (
    <Layout location={location} pageTitle={title}>
      <SEO
        title={seoTitle}
        description={shortDescription}
        pathname={slug}
        canonical={canonical}
        robots={`noindex, follow`}
      />
      <div className="container mx-auto px-6">
        <div className="flex justify-between items-center">
          <h1 className="text-4xl font-bold mt-6 mb-10">{title}</h1>
          <ContentListToggleIcons
            layoutType={layoutType}
            toggleLayout={toggleLayout}
          />
        </div>
        <ContentMasonry
          layoutType={layoutType}
          content={categories}
          slugModifier={slugModifier}
        />
        <ContentList
          layoutType={layoutType}
          content={categories}
          slugModifier={slugModifier}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query allCategoriesQuery {
    contentfulLayoutTemplate(title: { eq: "Categories" }) {
      title
      seoTitle
      shortDescription
      slug
      slugModifier
      canonical
    }
    allContentfulCategory(sort: { fields: slug, order: ASC }) {
      edges {
        node {
          id
          title
          slug
          canonical
          shortDescription
          seoTitle
          image {
            id
            fluid(maxWidth: 580) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
            description
          }
        }
      }
    }
  }
`
