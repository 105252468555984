import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons"
import BackgroundImage from "gatsby-background-image"

const ContentHorizontalCard = ({
  content: {
    title,
    type,
    slug,
    shortDescription,
    image,
    categories,
    published,
  },
  slugModifier,
}) => {
  return (
    <Link
      to={`/${
        slugModifier !== null && slugModifier !== undefined
          ? slugModifier + "/"
          : ""
      }${slug}/`}
    >
      <div className="rounded flex flex-row overflow-hidden shadow-lg hover:shadow-2xl mb-4 md:mb-6">
        <BackgroundImage tag="div" fluid={image.fluid}>
          <div className="h-24 w-24" />
        </BackgroundImage>
        <div className="w-full flex flex-col justify-center px-4 py-2">
          <span className="font-bold text-md">{title}</span>
          {(type === "Post" || type === "Review") &&
            published !== null &&
            published !== undefined && (
              <div className="hidden md:flex items-center text-sm">
                <FontAwesomeIcon
                  className="text-brand mr-2"
                  size="1x"
                  style={{
                    height: "1rem",
                    width: "1rem",
                  }}
                  icon={faCalendarAlt}
                />
                <span>{published}</span>
                <div className="hidden md:flex flex-wrap flex-grow justify-end -ml-1">
                  {categories !== null &&
                    categories !== undefined &&
                    categories.map(({ title, id }) => {
                      return (
                        <span
                          key={id}
                          className="text-sm bg-gray-200 py-1 px-2 mx-1 rounded-sm whitespace-no-wrap"
                        >
                          {title}
                        </span>
                      )
                    })}
                </div>
              </div>
            )}
        </div>
      </div>
    </Link>
  )
}

export default ContentHorizontalCard
