import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faThLarge, faBars } from "@fortawesome/free-solid-svg-icons"

const ContentListToggleIcons = ({ layoutType, toggleLayout }) => {
  return (
    <div className="flex ml-4">
      <FontAwesomeIcon
        className={`cursor-pointer m-2 ${
          layoutType === "list" ? "text-brand" : "hover:text-gray-700"
        }`}
        size="2x"
        style={{
          height: "2rem",
          width: "2rem",
        }}
        icon={faBars}
        onClick={(e) => {
          e.preventDefault()
          toggleLayout("list")
        }}
      />
      <FontAwesomeIcon
        className={`cursor-pointer m-2 ${
          layoutType === "masonry" ? "text-brand" : "hover:text-gray-700"
        }`}
        size="2x"
        style={{
          height: "2rem",
          width: "2rem",
        }}
        icon={faThLarge}
        onClick={(e) => {
          e.preventDefault()
          toggleLayout("masonry")
        }}
      />
    </div>
  )
}

export default ContentListToggleIcons
